<template>
  <div>
    <v-select
      :items="items"
      outlined
      dense
      :messages="$t('ec.action.update_delivery_status.help')"
      v-model="data.status"
    ></v-select>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    popupConfig: Object,
  },
  data: () => ({
    data: {
      status: null,
    },
  }),
  mounted() {
    this.data.status = this.originDeliveryStatus
  },
  computed: {
    originDeliveryStatus() {
      return this.popupConfig.originDeliveryStatus
    },
    items() {
      return this.$deliveryTypeEditable.map(status => ({
        text: this.$t(`ec.data.delivery_status.${status}`),
        value: status,
      }))
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', this.data)
      },
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
